import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class VsLeagueRanks extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiLeagueRanks.edges
    const rdata = this.props.data.allSheetRonaldoLeagueRanks.edges

    return (

      <Layout>
        <SEO
          title={"Messi and Ronaldo in Top 5 Leagues, Top 3 Leagues etc. - Messi and Ronaldo Goals and Stats in the Top Leagues"}
          description={`How many of Messi and Ronaldo's goals have come in the top ranked league? What about the Top 3 Leagues, or the Top 5 Leagues? Compare their goals, assists, hat-tricks and more.`}
          canonicalPath={`/all-time-stats/league-rankings-top/`}
        />

        <h1>
          <span className="sr-only">Messi and Ronaldo Goals and Stats</span> In Top X Leagues
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'In Top X Leagues', 'url': 'none', 'active': true },
            { 'name': 'At Each League Rank', 'url': '/all-time-stats/league-rankings/' },
          ]}
        />

        <CompetitionBlock competition="In 1st Ranked League" type="all" subtitle="League stats only" compTitle="In Top 1 Ranked Leagues" modalTitle="In Top 1 Ranked Leagues" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 2 Ranked Leagues" type="all" subtitle="League stats only" compTitle="In Top 2 Ranked Leagues" modalTitle="In Top 2 Ranked Leagues" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 3 Ranked Leagues" type="all" subtitle="League stats only" compTitle="In Top 3 Ranked Leagues" modalTitle="In Top 3 Ranked Leagues" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 4 Ranked Leagues" type="all" subtitle="League stats only" compTitle="In Top 4 Ranked Leagues" modalTitle="In Top 4 Ranked Leagues" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="vs Top 5 Ranked Leagues" type="all" subtitle="League stats only" compTitle="In Top 5 Ranked Leagues" modalTitle="In Top 5 Ranked Leagues" mdata={mdata} rdata={rdata} />

        <CopyBlock>
          <p>* For non-European leagues, the power rankings from <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://theanalyst.com/eu/2022/08/2022-23-football-stats/">Opta Analyst</a> are used.</p>

          <p>All European league rankings are based on <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://www.uefa.com/nationalassociations/uefarankings/country/">UEFA's coefficient rankings</a>.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Season</th>
                  <th>Messi League Ranking</th>
                  <th>Ronaldo League Ranking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2022/23</td>
                  <td>5th (Ligue 1)</td>
                  <td>28th (Saudi Pro League) <br/>1st (Premier League)</td>
                </tr>
                <tr>
                  <td>2021/22</td>
                  <td>5th (Ligue 1)</td>
                  <td>1st (Premier League)</td>
                </tr>
                <tr>
                  <td>2020/21</td>
                  <td>2nd (La Liga)</td>
                  <td>3rd (Serie A)</td>
                </tr>
                <tr>
                  <td>2019/20</td>
                  <td>1st (La Liga)</td>
                  <td>4th (Serie A)</td>
                </tr>
                <tr>
                  <td>2018/19</td>
                  <td>1st (La Liga)</td>
                  <td>3rd (Serie A)</td>
                </tr>
                <tr>
                  <td>2017/18</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2016/17</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2015/16</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2014/15</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2013/14</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2012/13</td>
                  <td>1st (La Liga)</td>
                  <td>1st (La Liga)</td>
                </tr>
                <tr>
                  <td>2011/12</td>
                  <td>2nd (La Liga)</td>
                  <td>2nd (La Liga)</td>
                </tr>
                <tr>
                  <td>2010/11</td>
                  <td>2nd (La Liga)</td>
                  <td>2nd (La Liga)</td>
                </tr>
                <tr>
                  <td>2009/10</td>
                  <td>2nd (La Liga)</td>
                  <td>2nd (La Liga)</td>
                </tr>
                <tr>
                  <td>2008/09</td>
                  <td>2nd (La Liga)</td>
                  <td>1st (Premier League)</td>
                </tr>
                <tr>
                  <td>2007/08</td>
                  <td>2nd (La Liga)</td>
                  <td>1st (Premier League)</td>
                </tr>
                <tr>
                  <td>2006/07</td>
                  <td>1st (La Liga)</td>
                  <td>2nd (Premier League)</td>
                </tr>
                <tr>
                  <td>2005/06</td>
                  <td>1st (La Liga)</td>
                  <td>3rd (Premier League)</td>
                </tr>
                <tr>
                  <td>2004/05</td>
                  <td>1st (La Liga)</td>
                  <td>2nd (Premier League)</td>
                </tr>
                <tr>
                  <td>2003/04</td>
                  <td>N/A</td>
                  <td>2nd (Premier League)</td>
                </tr>
                <tr>
                  <td>2002/03</td>
                  <td>N/A</td>
                  <td>7th (Primeira Liga)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiLeagueRanks(filter: {pgKey: {regex: "/ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
    allSheetRonaldoLeagueRanks(filter: {pgKey: {regex: "/ranking/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
        }
      }
    }
  }
`

export default VsLeagueRanks
